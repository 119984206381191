/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StaticNotification } from '@carbon/react';
import { observer } from 'mobx-react';

import { organizationStore } from 'stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import pluralize from 'utils/pluralize';
import detectConnectorsStore from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/detectConnectors/DetectConnectorsStore';

import { useConnectorsWithMissingSecrets } from './useConnectorsWithMissingSecrets';

const SecuredConnectorsMessage = () => {
  const { selectedClusterId } = deploymentStore;
  const { currentOrganization } = organizationStore;
  const { connectorElements: connectors } = detectConnectorsStore;

  const connectorsCount = useConnectorsWithMissingSecrets({
    currentOrganization,
    selectedClusterId,
    connectors
  });

  if (connectorsCount === 0) {
    return null;
  }

  return (
    <StaticNotification
      title={`${connectorsCount} ${pluralize('connector', connectorsCount)} without a secret ${pluralize('has', connectorsCount)} been found`}
      titleId="secured-connectors-notification-title"
      subtitle="Store connector credentials safely using Camunda secrets"
      kind="warning"
      lowContrast
      actionButtonLabel={`Update ${pluralize('secret', connectorsCount)}`}
      onActionButtonClick={() => {
        window.open(`${organizationStore.consoleDashboardPageUrl}/cluster/${selectedClusterId}/secrets`);
      }}
    />
  );
};

export default observer(SecuredConnectorsMessage);
