/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const DEV_LICENSE_MAX_COLLABORATORS = 5;
export const MAX_INPUT_EMAILS = 20;
export const DEV_LICENSE_MAX_INPUT_EMAILS = 4;
export const HTTP_DEBOUNCE_DELAY = 1500;
export const ASSET_REFRESH_DELAY = 5000;

export const STATUS_ASSET_REFRESH = 230;
export const STATUS_UNAUTHORIZED = 401;

export const ERROR_REASON_MAINTENANCE = 'MAINTENANCE_MODE';

export const NO_ORG_CLAIM = 'NO_ORG_CLAIM';

export const FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION =
  'There was a conflict in diagram saving, your last change may have been lost due to a force refresh.';

export const FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION =
  "Couldn't save your changes, are you offline? Please try again.";

export const VARCHAR_MAX = 255;
export const PASSWORD_VARCHAR_MAX = 72;

export const ORIGINAL_RELEASE_DATE = new Date('2022-03-01T08:00:00Z').valueOf();

export const JWT_ORGANIZATION_CLAIM = 'https://camunda.com/orgs';
export const DUMMY_ORGANIZATION_ID = '00000000-0000-0000-0000-000000000000';

export const BULK = 'BULK';
export const ZIP = 'zip';

export const DMN = 'DMN';
export const BPMN = 'BPMN';
export const FORM = 'FORM';
export const CONNECTOR_TEMPLATE = 'CONNECTOR_TEMPLATE';
export const PROCESS_APPLICATION = 'PROCESS_APPLICATION';
export const XML = 'XML';
export const FOLDER = 'FOLDER';
export const FOLDER_DEFAULT = 'DEFAULT';
export const DEFAULT = 'DEFAULT';
export const UNKNOWN = 'UNKOWN';
export const PROJECT = 'PROJECT';
export const DIAGRAM_TYPES = [DMN, BPMN, XML];

export const NO_PROCESS_ID = 'NO_PROCESS_ID';

export const ID_ALREADY_EXISTS = 'ID_ALREADY_EXISTS';

export const FILE_TYPE_MAPPING = {
  [BPMN]: 'bpmn',
  [DMN]: 'dmn',
  [DEFAULT]: 'project',
  [FOLDER]: 'folder',
  [FORM]: 'form',
  [CONNECTOR_TEMPLATE]: 'connector-template',
  [PROCESS_APPLICATION]: 'process-application'
};
export const EXECUTION_PLATFORM = 'Camunda Cloud';
export const C7_EXECUTION_PLATFORM = 'Camunda Platform';

// NOTE: `file-mapper.test.js`, and `VersionSelector.spec.js` should be updated when changing the default lint version, or adding new ones.
export const DEFAULT_ZEEBE_VERSION = '8.6.0';
export const DEFAULT_ZEEBE_VERSION_FORMS = '8.6.0';
export const AVAILABLE_LINT_VERSIONS = [
  '8.6.0',
  '8.5.0',
  '8.4.0',
  '8.3.0',
  '8.2.0',
  '8.1.0',
  '8.0.0',
  '1.3.0',
  '1.2.0',
  '1.1.0'
];
export const AVAILABLE_ALPHA_LINT_VERSIONS = ['8.7.0'];

export const EXPORTER = 'Camunda Web Modeler';
export const PAGE_TITLE_PREFIX = 'Modeler';

export const PAGE_TITLE_DIVIDER = '|';
// See https://reimagined-doodle-12d70320.pages.github.io/Development/Frontend/Bpmn-io-libraries#event-listeners for more information

export const BPMN_EVENTS_PRIORITY = {
  LOW: 500,
  HIGH: 2500
};
export const TARGET_ENV_PROD = 'prod';
export const TARGET_ENV_INT = 'int';

export const TARGET_ENV_DEV = 'dev';

export const PUBLIC_API_PATH_PREFIX = '/api/';
export const OIDC_TYPE_GENERIC = 'generic';
export const OIDC_TYPE_KEYCLOAK = 'keycloak';
export const OIDC_TYPE_MICROSOFT = 'microsoft';
export const IMPORT_DIALOG_SOURCE = 'import-dialog';
