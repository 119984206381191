/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableHead,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch
} from '@carbon/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DropTarget } from 'components';
import { containsSearchWord } from 'components/EntityTable/utils';
import { EmptyState } from 'primitives';

import * as Styled from './EntityTable.styled';

export default function EntityTable({
  action,
  batchActions = false,
  columns = [],
  emptyState = EmptyState,
  isLoading,
  onDrop = false,
  rows = [],
  title,
  noTablePadding = false,
  toolbarPlaceholder
}) {
  const getIdentityFn = () => (val) => val;

  const columnsConfig = columns.reduce((config, column) => {
    config[column.key] = {
      noPadding: column.noPadding,
      overflowVisible: column.overflowVisible,
      renderer: column.renderer
    };
    return config;
  }, {});

  const sortingPositionByRow = rows.reduce((config, row) => {
    config[row.id] = row.__sortingPosition;
    return config;
  }, {});

  function getCellClassNames({ noPadding, overflowVisible }) {
    return `${noPadding ? 'no-padding' : ''}${noPadding && overflowVisible ? ' ' : ''}${overflowVisible ? 'overflow-visible' : ''}`;
  }

  function filterRows({ cellsById, getCellId, headers, inputValue, rowIds }) {
    const searchWord = inputValue.trim().toLowerCase();

    return rowIds.filter((rowId) =>
      headers.some((header) => {
        const cell = cellsById[getCellId(rowId, header.key)];
        if (header.renderer) {
          return header.renderer.containsSearchWord?.(cell?.value, searchWord) ?? false;
        }
        return containsSearchWord(cell?.value, searchWord);
      })
    );
  }

  function sortRows(a, b, { compare, key, rowIds: [rowIdA, rowIdB], sortDirection, sortStates }) {
    const sortingPositionA = sortingPositionByRow[rowIdA];
    const sortingPositionB = sortingPositionByRow[rowIdB];

    if (sortingPositionA !== sortingPositionB) {
      return sortingPositionA - sortingPositionB;
    }

    let compared = 0;

    const renderer = columnsConfig[key].renderer;
    if (renderer) {
      const comparableValueA = renderer.getComparableValue?.(a) ?? 0;
      const comparableValueB = renderer.getComparableValue?.(b) ?? 0;
      compared = compare(comparableValueA, comparableValueB);
    } else {
      compared = compare(a, b);
    }

    if (sortDirection === sortStates.DESC) {
      compared *= -1;
    }

    return compared;
  }

  const getSkeleton = useCallback((headers) => {
    const visibleHeaders = headers.filter((header) => header.header?.length > 0);
    return (
      <DataTableSkeleton
        data-test="entity-table-skeleton"
        columnCount={visibleHeaders.length}
        headers={visibleHeaders}
        showHeader={false}
      />
    );
  }, []);

  return (
    <DataTable rows={rows} size="md" headers={columns} filterRows={filterRows} sortRow={sortRows}>
      {({
        rows: filteredRows,
        headers,
        getBatchActionProps,
        getHeaderProps,
        getRowProps,
        getSelectionProps,
        getTableProps,
        getToolbarProps,
        onInputChange,
        getTableContainerProps,
        selectAll,
        selectedRows
      }) => {
        const batchActionProps = getBatchActionProps();
        const selection = selectedRows.map((selectedRow) => rows.find((row) => row.id === selectedRow.id));
        return (
          <DropTarget isDisabled={!onDrop} onDrop={onDrop} displayStatus="block">
            <Styled.TableContainer title={title} $noTablePadding={noTablePadding} {...getTableContainerProps()}>
              {isLoading ? (
                getSkeleton(headers)
              ) : rows.length === 0 ? (
                emptyState
              ) : (
                <>
                  <TableToolbar {...getToolbarProps()} aria-label="entity table toolbar">
                    <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                      <TableToolbarSearch
                        onChange={onInputChange}
                        persistent
                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                        placeholder={toolbarPlaceholder}
                      />
                      {action?.({
                        tabIndex: batchActionProps.shouldShowBatchActions ? -1 : 0
                      })}
                    </TableToolbarContent>
                    {batchActions && (
                      <TableBatchActions {...batchActionProps}>
                        {batchActions.map((batchAction) => (
                          <TableBatchAction
                            key={batchAction?.title}
                            renderIcon={() => null}
                            tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                            disabled={!batchAction?.isAllowed?.(selection)}
                            onClick={(evt) => batchAction?.action?.(selection, () => selectAll(false), evt)}
                          >
                            {batchAction?.title}
                          </TableBatchAction>
                        ))}
                      </TableBatchActions>
                    )}
                  </TableToolbar>
                  <Table {...getTableProps()} data-test="entity-table" aria-label="entity table">
                    <TableHead>
                      <TableRow>
                        {batchActions && (
                          <TableSelectAll {...getSelectionProps()} aria-label="entity table select all" />
                        )}
                        {headers.map((header) => {
                          const { key, ...headerProps } = getHeaderProps({
                            header,
                            isSortable: header.sortable
                          });
                          return (
                            <Styled.TableHeader
                              key={key || header.key}
                              $customWidth={header.width}
                              $customMinWidth={header.minWidth}
                              {...headerProps}
                            >
                              {header.header}
                            </Styled.TableHeader>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows.map((row) => {
                        const { key, rowProps } = getRowProps({ row });
                        return (
                          <TableRow key={key || row.id} {...rowProps}>
                            {batchActions && (
                              <TableSelectRow
                                {...getSelectionProps({
                                  row
                                })}
                                aria-label={`entity table select ${row.id}`}
                              />
                            )}
                            {row.cells.map((cell) => {
                              const columnConfig = columnsConfig[cell.info.header];
                              const rendererFn = columnConfig?.renderer ?? getIdentityFn();
                              const dataTest = rendererFn.getDataTest?.(cell.value) ?? undefined;
                              const noPadding = columnConfig?.noPadding ?? false;
                              const overflowVisible = columnConfig?.overflowVisible ?? false;
                              return (
                                <Styled.TableCell
                                  key={cell.id}
                                  className={getCellClassNames({
                                    noPadding,
                                    overflowVisible
                                  })}
                                  {...(dataTest ? { 'data-test': dataTest } : undefined)}
                                >
                                  {rendererFn(cell.value)}
                                </Styled.TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
            </Styled.TableContainer>
          </DropTarget>
        );
      }}
    </DataTable>
  );
}

EntityTable.propTypes = {
  action: PropTypes.func,
  batchActions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  columns: PropTypes.array.isRequired,
  emptyState: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isLoading: PropTypes.bool,
  onDrop: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  rows: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired,
  toolbarPlaceholder: PropTypes.string
};
