/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, withRouter } from 'react-router-dom';
import { observer, Observer } from 'mobx-react';
import { C8PlayMode, PlayMode } from '@camunda/play';
import { is } from 'bpmn-js/lib/util/ModelUtil';

import {
  clustersStore,
  currentDiagramStore,
  diagramControlStore,
  notificationStore,
  organizationStore,
  processApplicationStore,
  realtimeCollaborationStore,
  userStore
} from 'stores';
import { dedicatedModesStore, deploymentErrorsStore, publicationStore } from 'App/Pages/Diagram/stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { connectorPollingService, fileService, projectService, Service, trackingService } from 'services';
import { DiscoverConnectorsModal, discoverConnectorsStore } from 'App/Pages/Diagram/DiscoverConnectorsModal';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import lintingStore from 'components/Modeler/LintingStore';
import { MONACO_LOADER_CONFIG } from 'components/CodeEditor/config';
import { Breadcrumb, ImportModal, TopBar } from 'components';
import { EmptyState } from 'primitives';
import { SadFace } from 'icons';
import getIdFromSlug from 'utils/getIdFromSlug';
import createPermission from 'utils/createPermission';
import buildSlug from 'utils/buildSlug';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { DMN } from 'utils/constants';
import { notificationStore as newNotificationStore } from 'components/NotificationSystem';
import { useAreAiFeaturesEnabled } from 'experiments/hooks';
import useIsPlayAcademyExperiment from 'experiments/play-academy-tutorial/useIsPlayAcademyExperiment';
import useIsPlayPersistDataExperiment from 'experiments/play-persist-data/useIsPlayPersistDataExperiment';

import Extensions from './Extensions';
import Header from './Header';
import ActionBar from './ActionBar';
import DmnViewer from './DmnViewer';
import BpmnViewer from './BpmnViewer';
import { errorPanelStore } from './ErrorPanel';
import * as Styled from './Diagram.styled';
import {
  BROWSE_MARKETPLACE_ONCLICK_EVENT,
  BROWSE_MARKETPLACE_ONDESTROY_EVENT,
  browseMarketplaceButtonEventManager
} from './BpmnJSExtensions/browseMarketplaceExtension';
import { hasBuiltInTemplates } from './BpmnJSExtensions/connectorsExtension';
import { InvalidDiagramDialog, invalidDiagramDialogStore } from './InvalidDiagramDialog';
import usePlayC8Enabled from './hooks/usePlayC8Enabled';
import getPlayAuthConfig from './utils/get-play-auth-config';
import { SendFeedbackButton } from './SendFeedback';

export const Diagram = (props) => {
  const { diagram, project, isFetching } = currentDiagramStore.state;
  const { modeler, isBPMN, lastReloadedContent, isNotFound, hasBeenValidated, isValid } = currentDiagramStore;
  const { isEditorOpen } = XMLEditorStore;

  const permission = createPermission(project?.permissionAccess);
  const { isImplementMode } = dedicatedModesStore;
  const [isDiscoverConnectorsModalOpen, setIsDiscoverConnectorsModalOpen] = useState(false);
  const location = useLocation();

  const service = useMemo(() => new Service(), []);

  const handlePostRequest = useCallback(service.post.bind(service), [service]);
  const handleGetRequest = useCallback(service.get.bind(service), [service]);

  const displayInfoNotification = useCallback(newNotificationStore.info.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displayErrorNotification = useCallback(newNotificationStore.error.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displaySuccessNotification = useCallback(newNotificationStore.success.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displayWarningNotification = useCallback(newNotificationStore.warning.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const fetchFileById = useCallback(fileService.fetchById.bind(fileService), [fileService]);
  const updateFileById = useCallback(fileService.update.bind(fileService), [fileService]);

  const convertLinkedFormsToEmbeddedForms = useCallback(
    fileService.convertLinkedFormsToEmbeddedForms.bind(fileService),
    [fileService]
  );
  const fetchFilesByDecisionId = useCallback(projectService.fetchFilesByDecisionId.bind(projectService), [
    projectService
  ]);
  const fetchFilesByProcessId = useCallback(projectService.fetchFilesByProcessId.bind(projectService), [
    projectService
  ]);

  const fetchFilesByFormId = useCallback(projectService.fetchFilesByFormId.bind(projectService), [projectService]);

  const mixpanelTrack = useCallback(trackingService.track.bind(trackingService), [trackingService]);

  const isPlayC8Enabled = usePlayC8Enabled();

  connectorPollingService.pollInboundConnectorStatus({
    isImplementMode,
    processId: currentDiagramStore.state?.diagram?.processId,
    interval: 5000,
    isAllowedToPoll: permission.is(['WRITE', 'ADMIN']) && isImplementMode
  });

  const [discoverConnectorsModalActionSource, setDiscoverConnectorsModalActionSource] = useState(null);

  const init = async () => {
    await currentDiagramStore.fetchDiagramById(getIdFromSlug(props.match.params.slug));
    dedicatedModesStore.init(isPlayC8Enabled);
    XMLEditorStore.init();
    consolidateUrlSlug();

    if (processApplicationStore.fromAProcessApplication) {
      await processApplicationStore.init(currentDiagramStore.state.diagram.folderId);
    }

    browseMarketplaceButtonEventManager.subscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
  };

  const reset = () => {
    currentDiagramStore.reset();
    diagramControlStore.reset();
    realtimeCollaborationStore.reset();
    deploymentErrorsStore.reset();
    dedicatedModesStore.reset();
    publicationStore.reset();
    formLinkStore.reset();
    XMLEditorStore.dispose();
    invalidDiagramDialogStore.reset();
    processApplicationStore.reset();

    browseMarketplaceButtonEventManager.unsubscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
    browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONDESTROY_EVENT);
  };

  const consolidateUrlSlug = () => {
    const url = document.location.href;
    const { diagram } = currentDiagramStore.state;

    if (!diagram) {
      return;
    }

    // Prevents faulty URL parsing if the user leaves the diagram
    // page fastly
    if (!url.includes('/diagrams/')) {
      return;
    }

    const slug = url.split('--')[1] ? url.split('--')[1].split('?')[0] : undefined;

    const splitStringSeparator = url.includes('/diagrams/xml/') ? '/diagrams/xml/' : '/diagrams/';

    const currentSlug = url.split(splitStringSeparator)[1].split('?')[0];
    const newSlug = buildSlug(diagram.id, diagram.name);

    if (!slug || currentSlug !== newSlug) {
      // the state has to be changed via the `history` prop,
      // otherwise the change won't be observed by the React Router when listening on history
      props.history.replace(`${splitStringSeparator}${newSlug}${document.location.search}`);
    }
  };

  // TODO(marcello.barile): Use _search to prefill the search input field in the dialog, see https://github.com/camunda/web-modeler/issues/6428
  const onBrowseMarketplaceButtonClick = (actionSource, _search) => {
    setDiscoverConnectorsModalActionSource(actionSource);
    setIsDiscoverConnectorsModalOpen(true);
  };

  const onDiscoverMarketplaceClose = () => {
    setIsDiscoverConnectorsModalOpen(false);
  };

  const resetMarketplaceSelection = () => {
    discoverConnectorsStore.reset();
  };

  const onMarketplaceImportComplete = async () => {
    if (!project) {
      throw new Error('Project is not defined');
    }

    await currentDiagramStore.loadElementTemplates(project.id);
  };

  const getSingleStartEvent = () => {
    if (!currentDiagramStore.modeler || !isBPMN) {
      return null;
    }

    const rootElement = currentDiagramStore.modeler.get('canvas').getRootElement();

    if (
      is(rootElement, 'bpmn:Process') &&
      rootElement.children.length === 1 &&
      is(rootElement.children[0], 'bpmn:StartEvent')
    ) {
      return rootElement.children[0];
    }

    return null;
  };

  useEffect(() => {
    (async () => {
      await init();
    })();

    return () => {
      reset();
    };
  }, [props.match.params.slug]);

  useEffect(() => {
    if (!isValid || !currentDiagramStore.modeler || !isBPMN) {
      return;
    }

    const singleStartEvent = getSingleStartEvent();

    if (singleStartEvent) {
      currentDiagramStore.selection?.select(singleStartEvent);
    }

    lintingStore.performLinting(currentDiagramStore.modeler, isImplementMode);
  }, [isBPMN, currentDiagramStore.modeler, isImplementMode, isValid]);

  useEffect(() => {
    const containsXML = location.pathname.includes('/xml/');

    if (isBPMN && modeler && containsXML && isImplementMode && permission.is(['WRITE', 'ADMIN'])) {
      XMLEditorStore.openEditor();
    }
  }, [isBPMN, modeler, isImplementMode]);

  useOpenXMLEditorForInvalidDiagram(hasBeenValidated, isValid, isEditorOpen);
  useCheckIfPublicAccessEnabled(isBPMN, modeler, lastReloadedContent);

  const areAIFeaturesEnabled = useAreAiFeaturesEnabled();
  const isPlayAcademyExperimentEnabled = useIsPlayAcademyExperiment();
  const isPlayPersistDataExperimentEnabled = useIsPlayPersistDataExperiment();

  if (isFetching) {
    return <Styled.DiagramLoader />;
  }

  if (isNotFound) {
    return (
      <>
        <TopBar.Breadcrumbs>
          <Breadcrumb title="Home" variant="link" to="/" />
        </TopBar.Breadcrumbs>
        <EmptyState
          title="Diagram not found!"
          description="Sorry, the diagram might have been deleted."
          icon={<SadFace width="48" height="48" />}
        />
      </>
    );
  }

  const Viewer = diagram.type === DMN ? DmnViewer : BpmnViewer;
  const hasResourcesToImport = !!discoverConnectorsStore.resourcesToImport?.length;
  const hasConnectors = currentDiagramStore.state.templates.length > 0 || hasBuiltInTemplates;
  const shouldShowModesTooltip = !getSingleStartEvent();

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(diagram.name)}</title>
      </Helmet>
      <Header permission={permission} />

      <ActionBar shouldShowModesTooltip={shouldShowModesTooltip} />

      {diagram.type !== DMN && dedicatedModesStore.isPlayMode ? (
        <Styled.Wrapper
          id={dedicatedModesStore.playModeAriaControl}
          data-test="zeebe-play"
          className="diagram-container"
          $showTemplates={currentDiagramStore.state.isShowingTemplate}
          $hasConnectors={hasConnectors}
          $isPlayMode
        >
          <Observer>
            {() => {
              return (
                <>
                  {isPlayC8Enabled ? (
                    <C8PlayMode
                      authConfig={getPlayAuthConfig(
                        deploymentStore.playClusterId,
                        organizationStore.currentOrganizationId,
                        clustersStore.clusters?.find((cluster) => cluster.uuid === deploymentStore.playClusterId)
                          ?.endpoints?.operate
                      )}
                      featureFlags={{
                        newContextPadEnabled: true, // TODO(philippfromme): remove once https://github.com/camunda/play/issues/555 is fixed
                        isAcademyVideoExperiment: isPlayAcademyExperimentEnabled,
                        areAIFeaturesEnabled
                      }}
                      monacoLoaderConfig={MONACO_LOADER_CONFIG}
                      xml={currentDiagramStore.state.diagram.content}
                      switchToImplementMode={(shouldOpenOutputTab) => {
                        dedicatedModesStore.setViewModeIndex(
                          dedicatedModesStore.availableViewModes.find((mode) => mode.label === 'Implement').index
                        );

                        if (shouldOpenOutputTab) {
                          errorPanelStore.switchToOutputTab();
                        }
                      }}
                      onDeploymentError={(errorObject) => {
                        deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', errorObject);
                      }}
                      projectId={currentDiagramStore.state.project?.id}
                      processId={currentDiagramStore.state.diagram?.processId}
                      processName={currentDiagramStore.state.diagram?.name}
                      displayNotification={{
                        info: displayInfoNotification,
                        warning: displayWarningNotification,
                        success: displaySuccessNotification,
                        error: displayErrorNotification
                      }}
                      mixpanelTrack={mixpanelTrack}
                      handlePostRequest={handlePostRequest}
                      fetchFilesByDecisionId={fetchFilesByDecisionId}
                      fetchFilesByProcessId={fetchFilesByProcessId}
                      fetchFilesByFormId={fetchFilesByFormId}
                      fetchFileById={fetchFileById}
                      isUserOrgOwnerOrAdmin={organizationStore.hasElevatedOrganizationPermissions}
                      organizationManagementPageUrl={organizationStore.organizationManagementPageUrl}
                      handleGetRequest={handleGetRequest}
                      consoleDashboardPageUrl={organizationStore.consoleDashboardPageUrl}
                      updateFileById={updateFileById}
                      originAppInstanceId={userStore.originAppInstanceId}
                      currentDiagramRevision={currentDiagramStore.state.diagram?.revision}
                      canUserSaveExampleData={permission.is(['WRITE', 'ADMIN'])}
                      diagramId={currentDiagramStore.state.diagram?.id}
                      FeedbackButton={SendFeedbackButton}
                    />
                  ) : (
                    <PlayMode
                      featureFlags={{
                        newContextPadEnabled: true, // TODO(philippfromme): remove once https://github.com/camunda/play/issues/555 is fixed
                        isAcademyVideoExperiment: isPlayAcademyExperimentEnabled,
                        areAIFeaturesEnabled,
                        isSaveExampleDataEnabled: isPlayPersistDataExperimentEnabled
                      }}
                      monacoLoaderConfig={MONACO_LOADER_CONFIG}
                      xml={currentDiagramStore.state.diagram.content}
                      switchToImplementMode={(shouldOpenOutputTab) => {
                        dedicatedModesStore.setViewModeIndex(
                          dedicatedModesStore.availableViewModes.find((mode) => mode.label === 'Implement').index
                        );

                        if (shouldOpenOutputTab) {
                          errorPanelStore.switchToOutputTab();
                        }
                      }}
                      onDeploymentError={(errorObject) => {
                        deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', errorObject);
                      }}
                      projectId={currentDiagramStore.state.project?.id}
                      processId={currentDiagramStore.state.diagram?.processId}
                      diagramId={currentDiagramStore.state.diagram?.id}
                      showNotification={notificationStore.showNotification}
                      displayNotification={{
                        info: displayInfoNotification,
                        warning: displayWarningNotification,
                        success: displaySuccessNotification,
                        error: displayErrorNotification
                      }}
                      mixpanelTrack={mixpanelTrack}
                      setExecutionPlatformVersion={currentDiagramStore.setExecutionPlatformVersion}
                      handlePostRequest={handlePostRequest}
                      fetchFilesByDecisionId={fetchFilesByDecisionId}
                      fetchFilesByProcessId={fetchFilesByProcessId}
                      fetchFileById={fetchFileById}
                      updateFileById={updateFileById}
                      originAppInstanceId={userStore.originAppInstanceId}
                      currentDiagramRevision={currentDiagramStore.state.diagram?.revision}
                      convertLinkedFormsToEmbeddedForms={convertLinkedFormsToEmbeddedForms}
                      canUserSaveExampleData={permission.is(['WRITE', 'ADMIN'])}
                      isUserOrgOwnerOrAdmin={organizationStore.hasElevatedOrganizationPermissions}
                      organizationManagementPageUrl={organizationStore.organizationManagementPageUrl}
                    />
                  )}
                </>
              );
            }}
          </Observer>
        </Styled.Wrapper>
      ) : (
        <>
          {isBPMN && config.marketplace?.enabled && (
            <>
              <ImportModal
                open={hasResourcesToImport}
                currentProject={project}
                showProjects={false}
                autoPublish
                resourcesToImportMetadata={discoverConnectorsStore.resourcesToImport}
                fetchDelay={2000}
                onClose={(evt) => {
                  resetMarketplaceSelection();

                  if (evt?.gotoMarketplace) {
                    setIsDiscoverConnectorsModalOpen(true);
                  }
                }}
                onPublishComplete={async () => {
                  lintingStore.performLinting(currentDiagramStore.modeler, isImplementMode);
                  await onMarketplaceImportComplete();
                  resetMarketplaceSelection();
                }}
              />

              <DiscoverConnectorsModal
                isOpen={isDiscoverConnectorsModalOpen}
                onClose={() => {
                  onDiscoverMarketplaceClose();
                }}
                actionSource={discoverConnectorsModalActionSource}
              />
            </>
          )}

          <Suspense fallback={<Styled.DiagramLoader />}>
            <Viewer
              permission={permission}
              experiments={{
                aiFeatures: areAIFeaturesEnabled
              }}
            />
          </Suspense>
          <Extensions />
        </>
      )}

      <InvalidDiagramDialog />
    </Fragment>
  );
};

export default withRouter(observer(Diagram));

const useCheckIfPublicAccessEnabled = (isBPMN, modeler, lastReloadedContent) => {
  /**
   * `lastReloadedContent` is needed in the dependency array
   * to update the `publicAccessEnabled` status, when changed during live collaboration
   */
  useEffect(() => {
    if (modeler && isBPMN) {
      publicationStore.checkIfPublicAccessEnabed(modeler);
    }
  }, [isBPMN, modeler, lastReloadedContent]);
};

/**
 * If the diagram is not valid, open the XML editor
 * and show the invalid diagram dialog. The dialog
 * will be shown only once per diagram, see the reset
 * method of its store.
 */
const useOpenXMLEditorForInvalidDiagram = (hasBeenValidated, isValid, isEditorOpen) => {
  useEffect(() => {
    if (hasBeenValidated && !isValid && !isEditorOpen) {
      invalidDiagramDialogStore.open();
      XMLEditorStore.openEditor();
    }
  }, [hasBeenValidated, isValid, isEditorOpen]);
};
