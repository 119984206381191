/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import NavigatedViewer from 'camunda-bpmn-js/lib/camunda-cloud/NavigatedViewer';

import { DiagramControls } from 'components';
import { waitForAttach } from 'components/Modeler/bpmn/utils';
import { Dialog, Button } from 'primitives';
import { ChevronLeft } from 'icons';
import { diagramControlStore, projectStore } from 'stores';
import { trackingService } from 'services';

import processTemplateStore from './ProcessTemplateStore';
import * as Styled from './TemplateDetailModal.styled';

export function TemplateDetailModal({ open = false, template = {}, onClose = () => {}, onBackToOverview = () => {} }) {
  const { content: diagramXml, name, created, createdByName, createdByUrl, technicalLevel, description } = template;

  const { isCreatingFile } = projectStore;

  const bpmnContainer = useRef();
  const viewer = useRef();

  useEffect(() => {
    if (diagramXml && bpmnContainer.current) {
      initBpmnJsViewer();
      initDiagramControlStore();

      trackingService.trackViewTemplate(diagramXml);
    }

    return () => {
      if (viewer.current) {
        diagramControlStore.reset();
        viewer.current.detach();
        viewer.current.destroy();
      }
    };
  }, [open]);

  const tagsNames = Object.keys(template.tags);
  const tagsValues = Object.values(template.tags);

  const initBpmnJsViewer = async () => {
    viewer.current = new NavigatedViewer({ container: bpmnContainer.current });

    await waitForAttach(viewer.current);

    try {
      viewer.current.importXML(diagramXml).then(() => {
        viewer.current.get('canvas').zoom('fit-viewport', true);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const initDiagramControlStore = () => {
    if (viewer.current) {
      diagramControlStore.setMinimapSupport(false);
      diagramControlStore.setResetViewportSupport(true);
      diagramControlStore.setResetViewportStrategy('fit-viewport');
      diagramControlStore.setFullscreenSupport(false);
      diagramControlStore.setModeler(viewer.current);
    }
  };

  return (
    <Dialog maxWidth={Styled.dialogWidth} open={open} onClose={onClose} clickOutsideToClose={false}>
      <Dialog.Header>
        <Styled.BackButton onClick={onBackToOverview}>
          <Styled.BackButtonIcon>
            <ChevronLeft width="16" height="16" />
          </Styled.BackButtonIcon>{' '}
          Back to overview
        </Styled.BackButton>
        <Dialog.Title>Process template details</Dialog.Title>
      </Dialog.Header>

      <Styled.DialogContainer>
        <Styled.TemplateDetails>
          <Styled.Name variant="h2" data-test="template-name">
            {name}
          </Styled.Name>

          <Styled.CreatedBy variant="h5" data-test="template-created-metadata">
            Created by{' '}
            <a href={createdByUrl} target="_blank" rel="noreferrer">
              {createdByName}
            </a>{' '}
            on {moment(created).format('ll')}
          </Styled.CreatedBy>

          <Styled.TechnicalLevel data-test="template-tech-level">
            <span>Technical level</span>
            <span>{technicalLevel}</span>
          </Styled.TechnicalLevel>

          <Styled.Description data-test="template-description" dangerouslySetInnerHTML={{ __html: description }} />

          <Styled.CategoriesContainer>
            {tagsValues.map((values, i) => (
              <Styled.TagsContainer key={tagsNames[i]} data-test="template-tags">
                <Styled.CategoryName>{tagsNames[i]}</Styled.CategoryName>
                {values.map((value) => (
                  <Styled.Tag key={value}>{value}</Styled.Tag>
                ))}
              </Styled.TagsContainer>
            ))}
          </Styled.CategoriesContainer>
        </Styled.TemplateDetails>

        <Styled.TemplatePreview>
          <Styled.DiagramContainer ref={bpmnContainer} />
          <Styled.DiagramControlsContainer>
            <DiagramControls />
          </Styled.DiagramControlsContainer>
        </Styled.TemplatePreview>
      </Styled.DialogContainer>

      <Styled.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Styled.CreateButton
          loading={isCreatingFile}
          loaderSize={'small'}
          lightLoader
          onClick={() => {
            processTemplateStore.createDiagramFromSelectedTemplate(template);
          }}
          data-test="create-model-from-template-button"
        >
          <span>Create model from template</span>
        </Styled.CreateButton>
      </Styled.Footer>
    </Dialog>
  );
}

TemplateDetailModal.propTypes = {
  open: PropTypes.bool,
  template: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackToOverview: PropTypes.func.isRequired
};

export default observer(TemplateDetailModal);
