/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, toJS } from 'mobx';

import EMAIL_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/sendgrid-connector.json';
import REST_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/http-json-connector.json';
import SLACK_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/slack-connector.json';
import GOOGLE_DRIVE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/google-drive-connector.json';
import AWS_DYNAMODB_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-dynamodb-connector.json';
import AWS_SQS_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sqs-connector.json';
import AWS_LAMBDA_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-lambda-connector.json';
import AWS_SNS_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sns-connector.json';
import KAFKA_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/kafka-connector.json';
import MSTEAMS_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/microsoft-teams-connector.json';
import GRAPHQL_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/graphql-connector.json';
import RABBITMQ_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/rabbitmq-connector.json';
import RABBITMQ_INBOUND_START_EVENT_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/rabbitmq-inbound-connector-start-event.json';
import RABBITMQ_INBOUND_INTERMEDIATE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/rabbitmq-inbound-connector-intermediate.json';
import WEBHOOK_START_EVENT_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/webhook-connector-start-event.json';
import WEBHOOK_INTERMEDIATE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/webhook-connector-intermediate.json';
import GITHUB_WEBHOOK_START_EVENT_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/github-webhook-connector-start-event.json';
import GITHUB_WEBHOOK_INTERMEDIATE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/github-webhook-connector-intermediate.json';
import UIPATH_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/uipath-connector.json';
import POWER_AUTOMATE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/power-automate-connector.json';
import BLUE_PRISM_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/blue-prism-connector.json';
import AUTOMATION_ANYWHERE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/automation-anywhere-connector.json';
import GITHUB_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/github-connector.json';
import OPENAI_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/openai-connector.json';
import CAMUNDA_OPERATE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/operate-connector.json';
import EASY_POST_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/easy-post-connector.json';
import GOOGLE_MAPS_PLATFORM_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/google-maps-platform-connector.json';
import ASANA_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/asana-connector.json';
import GITLAB_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/gitlab-connector.json';
import TWILIO_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/twilio-connector.json';
import KAFKA_INBOUND_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/kafka-inbound-connector.json';
import AWSSQS_START_EVENT_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sqs-start-event-connector.json';
import AWSSQS_INBOUND_INTERMEDIATE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sqs-inbound-intermediate-connector.json';
import TWILIO_WEBHOOK_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/twilio-webhook-connector.json';
import AWS_SNS_INBOUND_START from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sns-inbound-start-event.json';
import AWS_SNS_INBOUND_INTERMEDIATE from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sns-inbound-intermediate.json';
import GOOGLE_SHEETS_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/google-sheets-connector.json';
import TWILIO_WEBHOOK_INTERMEDIATE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/twilio-webhook-intermediate-connector.json';
import KAFKA_INBOUND_INTERMEDIATE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/kafka-inbound-connector-intermediate.json';
import AWS_EVENTBRIDGE_INBOUND_START_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-eventbridge-connector-start-event.json';
import AWS_EVENTBRIDGE_INBOUND_INTERMED_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-eventbridge-connector-intermediate.json';
import AWS_EVENTBRIDGE_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-eventbridge-connector.json';
import SLACK_START_EVENT_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/slack-inbound-start-event.json';
import SLACK_INBOUND_INTERMEDIATE_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/slack-inbound-intermediate.json';
import WHATS_APP_OUTBOUND_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/whatsapp-connector.json';
import SALESFORCE_OUTBOUND_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/salesforce-connector.json';
import SNS_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sns-inbound-boundary.json';
import SQS_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sqs-boundary-connector.json';
import KAFKA_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/kafka-inbound-connector-boundary.json';
import RABBITMQ_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/rabbitmq-inbound-connector-boundary.json';
import GITHUB_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/github-webhook-connector-boundary.json';
import WEBHOOK_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/webhook-connector-boundary.json';
import POLLING_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/http-polling-connector-boundary.json';
import HTTP_POLLING_CONNECTOR_TEMPLATES from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/http-polling-connector.json';
import EVENT_BRIDGE_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-eventbridge-connector-message-start.json';
import SNS_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sns-inbound-message-start.json';
import SQS_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sqs-start-message.json';
import GITHUB_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/github-webhook-connector-message-start.json';
import KAFKA_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/kafka-connector-message-start.json';
import RABBITMQ_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/rabbitmq-inbound-connector-message-start.json';
import SLACK_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/slack-inbound-message-start.json';
import WEBHOOK_MESSAGE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/webhook-connector-start-message.json';
import EVENT_BRIDGE_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-eventbridge-connector-boundary.json';
import SLACK_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/slack-inbound-boundary.json';
import TWILIO_BOUNDARY_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/twilio-webhook-boundary-connector.json';
import TWILIO_MESSGAE_START_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/twilio-webhook-message-start-connector.json';
import MSFT_O365_MAIL_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/microsoft-office365-mail-connector.json';
import AZURE_OPEN_AI_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/azure-open-ai-connector.json';
import HUGGING_FACE_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/hugging-face-connector.json';
import AWS_SAGEMAKER_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-sagemaker-outbound-connector.json';
import SQL_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/jdbc-connector.json';
import AWS_BEDROCK_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-bedrock-outbound-connector.json';
import AWS_TEXTRACT_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-textract-outbound-connector.json';
import AWS_COMPREHEND_CONNECTOR from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/.camunda/element-templates/aws-comprehend-outbound-connector.json';

class OOTBConnectorsStore {
  constructor() {
    makeAutoObservable(this);
  }

  getOOTBConnectors = () => {
    // toJS is necessary to remove Proxy object around objects
    return toJS([
      ASANA_CONNECTOR_TEMPLATES,
      GITLAB_CONNECTOR_TEMPLATES,
      REST_TEMPLATES,
      EMAIL_TEMPLATES,
      SLACK_TEMPLATES,
      GOOGLE_DRIVE_TEMPLATES,
      GOOGLE_MAPS_PLATFORM_CONNECTOR_TEMPLATES,
      AUTOMATION_ANYWHERE_CONNECTOR_TEMPLATES,
      AWS_SQS_TEMPLATES,
      AWS_LAMBDA_TEMPLATES,
      AWS_SNS_TEMPLATES,
      AWS_DYNAMODB_TEMPLATES,
      EASY_POST_CONNECTOR_TEMPLATES,
      RABBITMQ_TEMPLATES,
      KAFKA_TEMPLATES,
      MSTEAMS_TEMPLATES,
      GRAPHQL_TEMPLATES,
      UIPATH_CONNECTOR_TEMPLATES,
      POWER_AUTOMATE_CONNECTOR_TEMPLATES,
      OPENAI_CONNECTOR_TEMPLATES,
      CAMUNDA_OPERATE_CONNECTOR_TEMPLATES,
      WEBHOOK_START_EVENT_CONNECTOR_TEMPLATES,
      WEBHOOK_INTERMEDIATE_CONNECTOR_TEMPLATES,
      GITHUB_WEBHOOK_START_EVENT_CONNECTOR_TEMPLATES,
      GITHUB_WEBHOOK_INTERMEDIATE_CONNECTOR_TEMPLATES,
      GITHUB_CONNECTOR_TEMPLATES,
      TWILIO_CONNECTOR_TEMPLATES,
      BLUE_PRISM_CONNECTOR_TEMPLATES,
      RABBITMQ_INBOUND_START_EVENT_TEMPLATES,
      RABBITMQ_INBOUND_INTERMEDIATE_TEMPLATES,
      KAFKA_INBOUND_TEMPLATES,
      AWS_SNS_INBOUND_START,
      AWS_SNS_INBOUND_INTERMEDIATE,
      AWSSQS_START_EVENT_TEMPLATES,
      AWSSQS_INBOUND_INTERMEDIATE_TEMPLATES,
      TWILIO_WEBHOOK_TEMPLATES,
      TWILIO_WEBHOOK_INTERMEDIATE_TEMPLATES,
      AWS_EVENTBRIDGE_CONNECTOR_TEMPLATES,
      AWS_EVENTBRIDGE_INBOUND_START_TEMPLATES,
      AWS_EVENTBRIDGE_INBOUND_INTERMED_TEMPLATES,
      GOOGLE_SHEETS_CONNECTOR_TEMPLATES,
      KAFKA_INBOUND_INTERMEDIATE_TEMPLATES,
      SLACK_START_EVENT_TEMPLATES,
      SLACK_INBOUND_INTERMEDIATE_TEMPLATES,
      WHATS_APP_OUTBOUND_TEMPLATES,
      SALESFORCE_OUTBOUND_TEMPLATES,
      POLLING_BOUNDARY_CONNECTOR,
      HTTP_POLLING_CONNECTOR_TEMPLATES,
      SNS_BOUNDARY_CONNECTOR,
      SQS_BOUNDARY_CONNECTOR,
      KAFKA_BOUNDARY_CONNECTOR,
      RABBITMQ_BOUNDARY_CONNECTOR,
      GITHUB_BOUNDARY_CONNECTOR,
      WEBHOOK_BOUNDARY_CONNECTOR,
      EVENT_BRIDGE_MESSAGE_START_CONNECTOR,
      SNS_MESSAGE_START_CONNECTOR,
      SQS_MESSAGE_START_CONNECTOR,
      GITHUB_MESSAGE_START_CONNECTOR,
      KAFKA_MESSAGE_START_CONNECTOR,
      RABBITMQ_MESSAGE_START_CONNECTOR,
      SLACK_MESSAGE_START_CONNECTOR,
      WEBHOOK_MESSAGE_START_CONNECTOR,
      EVENT_BRIDGE_BOUNDARY_CONNECTOR,
      SLACK_BOUNDARY_CONNECTOR,
      TWILIO_BOUNDARY_CONNECTOR,
      TWILIO_MESSGAE_START_CONNECTOR,
      MSFT_O365_MAIL_CONNECTOR,
      AZURE_OPEN_AI_CONNECTOR,
      HUGGING_FACE_CONNECTOR,
      AWS_SAGEMAKER_CONNECTOR,
      SQL_CONNECTOR,
      AWS_BEDROCK_CONNECTOR,
      AWS_TEXTRACT_CONNECTOR,
      AWS_COMPREHEND_CONNECTOR
    ]);
  };
}

export default new OOTBConnectorsStore();
