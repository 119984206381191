/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { projectStore } from 'stores';
import { Dialog, Button, Spinner } from 'primitives';

import processTemplateStore from './ProcessTemplateStore';
import TemplateTile from './TemplateTile';
import * as Styled from './TemplateModal.styled';

export function TemplateModal({ open = false, onClose = () => {}, onOpenDetails = () => {} }) {
  const { availableTemplates, loading } = processTemplateStore;
  const { isCreatingFile } = projectStore;

  useEffect(() => {
    if (open) {
      processTemplateStore.fetchTemplates();
    }
  }, [open]);

  return (
    <Styled.Dialog maxWidth={Styled.dialogWidth} open={open} onClose={onClose} clickOutsideToClose={false}>
      <Dialog.Header>
        <Dialog.Title>Select a process template</Dialog.Title>
      </Dialog.Header>
      <Styled.DialogContainer>
        {loading ? (
          <Styled.TemplatesList>
            <Spinner fullHeight />
          </Styled.TemplatesList>
        ) : (
          <Styled.TemplatesList>
            <Styled.ScrollableList>
              <TemplateTile blank onTemplateSelected={() => processTemplateStore.createDiagramFromBlankTemplate()} />

              {availableTemplates.map((template) => (
                <TemplateTile
                  key={`tile-${template.id}`}
                  id={template.id}
                  name={template.name}
                  createdByName={template.createdByName}
                  createdAt={template.createdDate}
                  diagramXml={template.content}
                  isCreatingTemplate={isCreatingFile}
                  onOpenDetails={() => onOpenDetails(template)}
                  onTemplateSelected={() => processTemplateStore.createDiagramFromSelectedTemplate(template)}
                />
              ))}
            </Styled.ScrollableList>
          </Styled.TemplatesList>
        )}
      </Styled.DialogContainer>
      <Styled.Footer>
        <Button variant="secondary" onClick={onClose} data-test="template-modal-cancel-button">
          Cancel
        </Button>
      </Styled.Footer>
    </Styled.Dialog>
  );
}

TemplateModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpenDetails: PropTypes.func
};

export default observer(TemplateModal);
